import "@babel/polyfill";
import $ from 'jquery';
import loader from './__modules/loader';

document.addEventListener('DOMContentLoaded', () => {
	loader();
});

window.addEventListener('load', () => {

	$(".burger-menu").click(function () {
		if (!$(".burger-menu").hasClass("active")) {
			$(".menu").slideDown("medium");
			$(".burger-menu").addClass("active");
			let getClassMenuMobile = $(".menu-mobile-item");
			let timingStart = 0;
			let timingEnd = 200;
			for (let index = 0; index < getClassMenuMobile.length; index++) {
				timingStart += timingEnd;
				$(`.menu-mobile-item:eq(${index}) a`).finish().delay(timingStart).animate({ top: "0", opacity: 1 }, 500);
			}
			$(".menu-mobile-item a").removeAttr("style")
		} else {
			$('.header').css({ transform: "translateY(0%)", transition: ".3s ease-in-out" });
			$(".menu").slideUp("medium");
			$(".burger-menu").removeClass("active");
			$(".menu-mobile-item a").removeAttr("style")
		}
	});

	if ($(".headerSlider").length > 0) {
		var swiper = new Swiper(".headerSlider", {
			effect: "fade",
			speed: 800,
			delayBetweenSlides: 800,
			navigation: {
			  nextEl: ".swiper-button-next",
			  prevEl: ".swiper-button-prev",
			},
		});
	}


	if ($(".myServices").length > 0) {
	var swiper = new Swiper(".myServices", {
		slidesPerView: 1.2,
		centeredSlides: true,
		spaceBetween: 30,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		speed: 800,
		breakpoints: {
		    640: {
		      slidesPerView: 1,
		      spaceBetween: 20,
		    },
		    768: {
		      slidesPerView: 2,
		      spaceBetween: 1,
			//   centeredSlides: false,
		    },
			992: {
				slidesPerView: 2,
				spaceBetween: 1,
			    centeredSlides: false,
			  },
		    1400: {
		      slidesPerView: 3,
		      spaceBetween: 0.5,
			  centeredSlides: false,
		    },
		},
	  });
	}

	if ($(".clients").length > 0) {
		var swiper = new Swiper(".clients", {
			effect: "fade",
			fadeEffect: {
				crossFade: true
			},
			speed: 800,
			delay: 800,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				dynamicBullets: true,
			},
		});
	}

});


window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {
    if ($('.projects-section').length > 0) {
        let projectsSection = $(".projects-section").offset().top + 300;
        let windowHeight = $(window).height();
        let scrollPosition = $(window).scrollTop();
        if (scrollPosition > (projectsSection - windowHeight)) {
            let headings = $("[data-number]");
            function increaseNumbers() {
                let allReached = true;
                headings.each(function () {
                    let data = parseInt($(this).data("number"));
                    let currentValue = parseInt($(this).text());
                    if (currentValue < data) {
                        allReached = false;
                        $(this).text(currentValue + 1 + "+");
                    }
                });
                if (!allReached) {
                    setTimeout(increaseNumbers, 100);
                }
            }
            increaseNumbers();
        }
    }

	if (scrollY > 10) {
		$(".header").addClass("header--smaller");
	} else {
		$(".header").removeClass("header--smaller");
	}
});

